<template>
  <PegawaiForm mode="Tambah" module="Pegawai"> </PegawaiForm>
</template>

<script>
import PegawaiForm from './form';

const PegawaiAdd = {
  name: 'PegawaiAdd',
  components: { PegawaiForm },
};

export default PegawaiAdd;
</script>
